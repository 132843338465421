<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ conts.title }} <span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <a @click="navigate('/admin/news')" style="cursor: pointer">
                {{ conts.breadcrumb.dashboard }}
              </a>
            </li>
            <li>
              <a @click="navigate('/admin/user')" style="cursor: pointer">
                {{ conts.breadcrumb.facility_staff }}</a
              >
            </li>
            <li>
              <span> {{ conts.breadcrumb.details }}</span>
            </li>
          </ul>
        </nav>
      </div>

      <div class="toggle_wrap">
        <div class="toggle_dsn">
          <a @click="navigateToShow()" style="cursor: pointer">表示</a>
          <span class="modeon">編集</span>
        </div>
        <!--toggle_dsn-->
      </div>

      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          {{ conts.error_message.common_error }}
          <span><i class="fas fa-times msg_remove"></i></span>
        </p>
      </div>

      <Spinner v-if="spinner" />

      <div
        class="modal"
        id="discardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form_outwrap">
                  <div class="modal_header">
                    <p class="ttl_header">{{ conts.discard.title }}</p>
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <p>
                          <span>{{ conts.discard.content }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          name=""
                          :value="conts.button.cancel"
                          :title="conts.button.cancel"
                          class="close"
                          dusk="delete_data"
                          @click="closeDiscard()"
                        >
                          {{ conts.button.cancel }}
                        </button>
                      </div>
                      <div class="btn_norm delete">
                        <button
                          @click="discardModal()"
                          class="btn btn-primary cln_form_btn"
                          :value="conts.button.execution"
                          :title="conts.button.execution"
                          dusk="save"
                        >
                          {{ conts.button.execution }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn"
            class="pop-remove_btn ttoltip tip-top close"
            @click="closeDiscard()"
          >
            <i class="fas fa-times"></i>
          </p>
        </div>
        <!--modal_inner-->
      </div>

      <Form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit="onSubmit"
        :validation-schema="schema"
        v-slot="{ errors }"
        class="createForm"
      >
        <!--command_wrap-->
        <div class="command_wrap command_bottom">
          <div class="leftarea btn_wrap">
            <div class="return_link">
              <a id="backToList" dusk="to_list_screen"
                ><span class="icon"><i class="fas fa-chevron-left"></i></span
                >{{ conts.staff_list }}</a
              >
            </div>
            <div></div>
          </div>
          <!--leftarea-->
          <div class="rightarea">
            <div>
              <div class="btn_norm">
                <button type="submit" dusk="com_save_button">保存</button>
              </div>
            </div>
            <div class="commandtext_wrap">
              <div class="commandtextlist"></div>
            </div>
            <!--commandtext_wrap-->
          </div>
          <!--rightarea-->
        </div>

        <Spinner v-if="spinner" />
        <!--modal-->
        <div class="contentout_wrap">
          <div class="content_wrap twocolumn">
            <div class="box_wrap left">
              <h2 class="ttl_2">{{ conts.title }}</h2>
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="formtable">
                    <tbody>
                      <tr>
                        <th class="wid_20per">
                          {{ conts.grid.name }}
                          <span class="required">{{ conts.grid.required }}</span>
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_50per">
                              <p class="ttl_3">{{ conts.grid.last_name }}</p>
                              <div class="in_wrap">
                                <Field
                                  name="last_name"
                                  type="text"
                                  class=""
                                  :placeholder="conts.place_holder.last_name"
                                  :class="{ 'is-invalid': errors.last_name }"
                                  v-model="editUser.last_name"
                                  id="last_name"
                                />
                                <div class="msg_wrap">
                                  <span class="each_msg">
                                    {{ errors.last_name }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="wrap wid_50per">
                              <p class="ttl_3">{{ conts.grid.first_name }}</p>
                              <div class="in_wrap">
                                <div class="in_wrap">
                                  <Field
                                    name="first_name"
                                    type="text"
                                    class=""
                                    :placeholder="conts.place_holder.first_name"
                                    :class="{ 'is-invalid': errors.first_name }"
                                    v-model="editUser.first_name"
                                    id="first_name"
                                  />
                                  <div class="msg_wrap">
                                    <span class="each_msg">
                                      {{ errors.first_name }}
                                      <span class="trg"></span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="wrap wid_50per">
                              <p class="ttl_3">
                                {{ conts.grid.last_name_katakana }}
                              </p>
                              <div class="in_wrap">
                                <Field
                                  name="last_name_kana"
                                  type="text"
                                  class=""
                                  :placeholder="conts.place_holder.last_name_kana"
                                  :class="{
                                    'is-invalid': errors.last_name_kana,
                                  }"
                                  v-model="editUser.last_name_kana"
                                  id="last_name_kana"
                                />
                                <div class="msg_wrap" v-if="!existsError.last_name_kana">
                                  <span class="each_msg">
                                    {{ errors.last_name_kana }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                                <div class="msg_wrap" v-if="existsError.last_name_kana">
                                  <span class="each_msg">
                                    {{ conts.error_message.last_name_kana_error }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="wrap wid_50per">
                              <p class="ttl_3">
                                {{ conts.grid.first_name_katakana }}
                              </p>
                              <div class="in_wrap">
                                <Field
                                  name="first_name_kana"
                                  type="text"
                                  class=""
                                  :placeholder="conts.place_holder.first_name_kana"
                                  :class="{
                                    'is-invalid': errors.first_name_kana,
                                  }"
                                  v-model="editUser.first_name_kana"
                                  id="first_name_kana"
                                />
                                <div class="msg_wrap" v-if="!existsError.first_name_kana">
                                  <span class="each_msg">
                                    {{ errors.first_name_kana }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                                <div class="msg_wrap" v-if="existsError.first_name_kana">
                                  <span class="each_msg">
                                    {{ conts.error_message.first_name_kana_error }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="form_outwrap" style="display: none">
                              <div class="wrap wid_100per">
                                <p class="ttl_3">
                                  {{ conts.grid.alphabet }}
                                  <small class="input_description m_l10">{{
                                    conts.grid.alphabet_dec
                                  }}</small>
                                </p>
                                <div class="in_wrap">
                                  <Field
                                    name="alphabet_name"
                                    type="text"
                                    class=""
                                    :class="{
                                      'is-invalid': errors.alphabet_name,
                                    }"
                                    v-model="editUser.alphabet_name"
                                    id="alphabet_name"
                                  />
                                  <div class="msg_wrap">
                                    <span class="each_msg">
                                      {{ errors.alphabet_name }}
                                      <span class="trg"></span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_20per">
                          {{ conts.grid.user_code
                          }}<span class="required">{{ conts.grid.required }}</span>
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per">
                              <div class="in_wrap">
                                <p class="ttl_3">
                                  <small class="input_description">{{
                                    conts.grid.user_code_dec
                                  }}</small>
                                </p>
                                <Field
                                  autcomplete="nope"
                                  name="user_code"
                                  type="text"
                                  class=""
                                  :class="{
                                    'is-invalid': errors.user_code,
                                  }"
                                  v-model="editUser.user_code"
                                  :placeholder="conts.place_holder.user_code"
                                  id="userCode"
                                  value="dddd"
                                  ref="userCode"
                                />
                                <div class="msg_wrap" v-if="!existsError.user_code">
                                  <span class="each_msg">
                                    {{ errors.user_code }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                                <div class="msg_wrap" v-if="existsError.user_code">
                                  <span class="each_msg">
                                    {{ conts.error_message.user_code_exists }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_20per">
                          {{ conts.grid.contact_address
                          }}<span class="required">{{ conts.grid.required }}</span>
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per">
                              <p class="ttl_3">{{ conts.grid.mail_address }}</p>
                              <div class="in_wrap">
                                <Field
                                  name="email"
                                  type="email"
                                  class=""
                                  :class="{
                                    'is-invalid': errors.email,
                                  }"
                                  v-model="editUser.email"
                                  :placeholder="conts.place_holder.email"
                                  id="email"
                                />
                                <div class="msg_wrap" v-if="!existsError.email">
                                  <span class="each_msg">
                                    {{ errors.email }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                                <div class="msg_wrap" v-if="existsError.email">
                                  <span class="each_msg">
                                    {{ conts.error_message.email_exists }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per">
                              <p class="ttl_3">
                                {{ conts.grid.tel
                                }}<small class="not_required">{{
                                  conts.grid.phone
                                }}</small>
                              </p>
                              <div class="in_wrap">
                                <Field
                                  id="phone"
                                  type="text"
                                  name="phone"
                                  class="alpha_num_sym"
                                  :class="{
                                    'is-invalid': errors.phone,
                                  }"
                                  v-model="editUser.phone"
                                  :placeholder="conts.place_holder.phone"
                                />

                                <div class="msg_wrap">
                                  <span class="each_msg">
                                    {{ errors.phone }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_20per">
                          {{ conts.grid.change_password }}
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per">
                              <div class="in_wrap">
                                <p class="ttl_3">
                                  <small class="input_description">{{
                                    conts.grid.password_dec
                                  }}</small>
                                </p>
                                <Field
                                  name="password"
                                  type="password"
                                  class="alpha_num_sym"
                                  :class="{
                                    'is-invalid': errors.password,
                                  }"
                                  v-model="editUser.password"
                                  id="password"
                                  :placeholder="conts.place_holder.password"
                                />
                                <div class="msg_wrap">
                                  <span class="each_msg">
                                    {{ errors.password }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per">
                              <div class="in_wrap">
                                <p class="ttl_3">
                                  <small class="input_description">{{
                                    conts.grid.password_dec
                                  }}</small>
                                </p>
                                <Field
                                  name="password_confirmation"
                                  type="password"
                                  class="alpha_num_sym"
                                  v-model="editUser.password_confirmation"
                                  :class="{
                                    'is-invalid': errors.password_confirmation,
                                  }"
                                  id="password"
                                  ref="password"
                                  :placeholder="conts.place_holder.password_confirmation"
                                />
                                <div class="msg_wrap">
                                  <span class="each_msg">
                                    {{ errors.password_confirmation }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_20per">
                          {{ conts.grid.section }}
                        </th>
                        <td>
                          <div class="form_outwrap" v-if="!hideSection">
                            <div class="wrap wid_100per p_r0">
                              <div>
                                <p class="ttl_3">
                                  {{ conts.grid.section }}
                                </p>
                              </div>
                              <div class="in_wrap">
                                <div class="select_wrap">
                                  <Field
                                    required=""
                                    name="section"
                                    as="select"
                                    class="form-control"
                                    v-model="editUser.section"
                                    @change="onChange"
                                  >
                                    <option
                                      v-for="section in sections"
                                      :key="section.id"
                                      :value="section.id"
                                    >
                                      {{ section.title }}
                                    </option>
                                  </Field>
                                </div>
                                <div class="msg_wrap">
                                  <span class="each_msg">
                                    {{ errors.section }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0">
                              <div>
                                <p class="ttl_3">
                                  {{ conts.grid.group }}
                                </p>
                              </div>
                              <div class="in_wrap">
                                <div class="select_wrap">
                                  <Field
                                    required=""
                                    name="group"
                                    as="select"
                                    class="form-control"
                                    v-model="defaultGroupId"
                                    @change="changeDepartment($event)"
                                  >
                                    <option value="">
                                      {{ conts.place_holder.group }}
                                    </option>
                                    <option
                                      v-for="group in selectedGroup"
                                      :key="group.id"
                                      :value="group.id"
                                      readonly
                                    >
                                      {{ group.name }}
                                    </option>
                                  </Field>
                                </div>
                                <div class="msg_wrap">
                                  <span class="each_msg">
                                    {{ errors.group }}
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_20per">
                          {{ conts.grid.position }}
                        </th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per">
                              <div class="in_wrap">
                                <p class="ttl_3">
                                  <small class="input_description">{{
                                    conts.grid.position
                                  }}</small>
                                </p>
                                <div class="select_wrap">
                                  <Field
                                    required=""
                                    name="role"
                                    as="select"
                                    class="form-control"
                                    v-model="editUser.role"
                                    @change="setRoleRank($event)"
                                  >
                                    <option value="" data-rank="">
                                      {{ conts.place_holder.position }}
                                    </option>
                                    
                                    <option value="取締役会長" data-rank="1000">取締役会長</option>
                                    <option value="代表取締役社長" data-rank="900">
                                      代表取締役社長
                                    </option>
                                    <option value="取締役副社長" data-rank="800">取締役副社長</option>
                                    <option value="専務取締役" data-rank="700">
                                      専務取締役
                                    </option>
                                    <option value="取締役" data-rank="600">取締役</option>
                                    <option value="部長執行役員" data-rank="500">
                                      部長執行役員
                                    </option>
                                    <option value="部長" data-rank="400">部長</option>
                                    <option value="課長" data-rank="300">課長</option>
                                    <option value="係長" data-rank="200">係長</option>
                                    <option value="主任" data-rank="100">主任</option>
                                  </Field>
                                </div>

                                <div class="msg_wrap" v-if="departmentValidation">
                                  <span class="each_msg">
                                    役職を選択する前に部門を選択してください。
                                    <span class="trg"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--formtable-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </div>
            <!--box_wrap-->

            <!--box_wrap-->
            <div class="box_wrap right">
              <!-- <h2 class="ttl_2">公開情報</h2> -->
              <div class="form_outwrap">
                <div class="wrap wid_100per p_r0" v-if="profilePicture">
                  <div>
                    <p class="ttl_3">
                      アイキャッチ<br /><small class="input_description"
                        >推奨サイズ：縦400×横270</small
                      >
                    </p>
                  </div>
                  <div class="in_wrap ec_wrap">
                    <input
                      type="hidden"
                      name="ec_id"
                      value="$ec_val->id"
                      class="ec_catch eye_catch_edit"
                    />
                    <img
                      :src="profilePicture ? 'data:image/png;base64,' + viewImage : ''"
                      alt=""
                    />
                    <p
                      id="ec_delete"
                      class="remove_btn tooltip tip-left"
                      title="削除"
                      dusk="delete_eyecatch"
                    >
                      <i class="fas fa-times"></i>
                    </p>
                    <div id="prev"></div>
                  </div>
                  <!--in_wrap-->
                </div>
                <div class="wrap wid_100per p_r0" v-else>
                  <div><p class="ttl_3">アイキャッチ</p></div>
                  <div class="in_wrap">
                    <div class="filegroup m_b20">
                      <input
                        type="file"
                        id="ec_pls"
                        name="eyecatch"
                        value=""
                        class="ec_catch eye_catch_create"
                        accept=".jpg"
                      />

                      <label for="ec_pls" class="filelabel">ファイルを選択</label>
                      <input type="hidden" name="ec_pastdel" value="del" />
                    </div>
                  </div>
                  <div id="prev"></div>
                </div>

                <div class="wrap wid_100per p_r0">
                  <div>
                    <p class="ttl_3">
                      {{ conts.grid.authority }}
                    </p>
                  </div>
                  <div class="in_wrap">
                    <div class="form_outwrap">
                      <div class="wrap">
                        <div class="in_wrap">
                          <label>
                            <Field
                              type="radio"
                              name="is_admin"
                              :value="true"
                              v-model="editUser.is_admin"
                              class="radiobox"
                            /><span :dusk="conts.grid.is_admin"></span
                            ><span class="radiotxt">{{ conts.grid.is_admin }}</span>
                          </label>
                        </div>
                      </div>
                      <div class="wrap">
                        <div class="in_wrap">
                          <label>
                            <Field
                              type="radio"
                              name="is_admin"
                              :value="false"
                              v-model="editUser.is_admin"
                              class="radiobox"
                            /><span :dusk="conts.grid.is_user"></span
                            ><span class="radiotxt">{{ conts.grid.is_user }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="msg_wrap">
                      <span class="each_msg">
                        {{ errors.is_admin }}
                        <span class="trg"></span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="wrap wid_100per p_r0">
                  <div>
                    <p class="ttl_3">
                      {{ conts.grid.sex
                      }}<span class="required">{{ conts.grid.required }}</span>
                    </p>
                  </div>
                  <div class="in_wrap">
                    <div class="form_outwrap">
                      <div class="wrap">
                        <div class="in_wrap">
                          <label>
                            <Field
                              type="radio"
                              name="sex"
                              :value="conts.grid.male"
                              v-model="editUser.sex"
                              class="radiobox"
                            /><span :dusk="conts.grid.male"></span
                            ><span class="radiotxt">{{ conts.grid.male }}</span>
                          </label>
                        </div>
                      </div>
                      <div class="wrap">
                        <div class="in_wrap">
                          <label>
                            <Field
                              type="radio"
                              name="sex"
                              :value="conts.grid.female"
                              v-model="editUser.sex"
                              class="radiobox"
                            /><span :dusk="conts.grid.female"></span
                            ><span class="radiotxt">{{ conts.grid.female }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="msg_wrap">
                      <span class="each_msg">
                        {{ errors.sex }}
                        <span class="trg"></span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="wrap p_r0">
                  <p class="ttl_3">
                    {{ conts.grid.hire_date
                    }}<span class="required">{{ conts.grid.required }}</span>
                  </p>
                  <div class="in_wrap time_wrap">
                    <div class="ttl_3">
                      <Field
                        type="date"
                        name="here_date"
                        :placeholder="conts.place_holder.hire_date"
                        class="here-date"
                        autocomplete="off"
                        v-model="editUser.here_date"
                      />
                      <span style="display: none">@</span>
                      <input
                        style="display: none"
                        name="public_hour"
                        type="text"
                        placeholder="00"
                        class="hour"
                      />
                      <span style="display: none">:</span>
                      <input
                        style="display: none"
                        name="public_sec"
                        type="text"
                        placeholder="00"
                        class="minutes"
                      />
                    </div>

                    <div class="msg_wrap" v-if="!hireDateFormat">
                      <span class="each_msg">
                        {{ errors.here_date }}
                        <span class="trg"></span>
                      </span>
                    </div>
                    <div class="msg_wrap" v-if="hireDateFormat">
                      <span class="each_msg">
                        {{ conts.error_message.date_format }}
                        <span class="trg"></span>
                      </span>
                    </div>

                    <!--datepickbox-->
                  </div>
                  <!--in_wrap-->
                </div>

                <!--wrap-->
              </div>
              <!--form_outwrap-->
            </div>
            <!--box_wrap-->
          </div>
          <!--content_wrap-->
        </div>
        <!--contentoutwrap-->
      </Form>
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
/* eslint-disable */
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import Spinner from "../../../components/control_panel/Spinner.vue";
import CONST from "../../../const/control_panel/user";
import Group from "../../../apis/Groups";
import "@/assets/front_component/js/timepicker/jquery.ui.datepicker-ja.min.js";
import $ from "jquery";
import User from "../../../apis/admin_User";

$(function () {
  //▼datepicker
  $(".input-date").datepicker({
    format: "yyyy-m-d",
    autoclose: true,
  });

  $(".set-now-date").on("click", function () {
    $(this).siblings(".input-date").datepicker("setDate", new Date());
  });
});

export default {
  name: "User Create",
  setup() {
    let schema = Yup.object().shape(
      {
        first_name: Yup.string().required(CONST.CREATE.error_message.first_name),
        last_name: Yup.string().required(CONST.CREATE.error_message.last_name),
        last_name_kana: Yup.string().required(CONST.CREATE.error_message.last_name_kana),
        first_name_kana: Yup.string().required(
          CONST.CREATE.error_message.first_name_kana
        ),
        user_code: Yup.string().required(CONST.CREATE.error_message.user_code),
        email: Yup.string()
          .required(CONST.CREATE.error_message.email)
          .email(CONST.CREATE.error_message.email_format),
        password: Yup.string().notRequired(),
        password_confirmation: Yup.string()
          .when('password', {
            is: (password) => password && password.trim() !== '',
            then: Yup.string().required(CONST.CREATE.error_message.password_confirmation).oneOf(
            [Yup.ref("password")],
            "確認パスワードはパスワードと同じを指定してください。"
          ),
            otherwise: Yup.string().notRequired(),
          }),
        sex: Yup.string().required(CONST.CREATE.error_message.sex),
        is_admin: Yup.string().required(CONST.CREATE.error_message.is_admin),
        here_date: Yup.string().required(CONST.CREATE.error_message.hire_date),
        phone: Yup.string()
          .nullable()
          .notRequired()
          .when("phone", {
            is: (value) => value?.length,
            then: (rule) =>
              rule.matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                CONST.CREATE.error_message.phone_format
              ),
          }),
      },
      [
        // Add Cyclic deps here because when require itself
        ["phone", "phone"],
      ]
    );
    return {
      schema,
    };
  },
  data() {
    return {
      conts: CONST.EDIT,
      editUser: {
        role: "",
        rank: "",
      },
      commonValidationError: false,
      formInputChange: false,
      date: "",
      hours: "00",
      minutes: "00",
      hoursValidated: true,
      minutesValidated: true,
      dateValidated: true,
      existsError: {},
      gotoList: true,
      sections: [],
      group: [],
      selectedGroup: [],
      seletcedRole: [],
      hideSection: true,
      userImageUpload: "",
      profilePicture: {},
      updateImage: false,
      viewImage: "",
      hireDateFormat: false,
      beforeGroupId: "",
      updateGroupId: "",
      defaultGroupId: "",
      departmentValidation: false,
      isDepartmentSelected: false,
      isRemovePicture: false,
      isChangedRank: false,
    };
  },
  created() {
    this.getUser(this.$route.params.id);
    this.getPicture(this.$route.params.id);
    this.getGroups();
    this.getRole();
  },
  computed: {
    ...mapState("user", ["viewUser", "validationError", "groups", "role"]),
    ...mapState("common", ["spinner"]),
    ...mapState("file", ["picture"]),
  },

  watch: {
    viewUser(val) {
      this.editUser = val;

      this.editUser.role = val.group ? val.group.group_role : "";

      this.editUser.rank = val.group ? val.group.group_rank : "";

      this.defaultGroupId = val.group ? val.group.id : "";

      this.beforeGroupId = val.group;

      this.updateGroupId = val.group ? val.group.id : "";

      document.getElementById("userCode").value = "";

      setInterval(() => {}, 100);

      this.$refs.userCode.value = val.user_code;
    },

    validationError(error) {
      this.existsError = error;
    },
    picture(img) {
      this.profilePicture = img[0];
      this.viewImage = img != "" ? img[0].base64 : "";
    },
    groups(groups) {
      this.selectedGroup = groups;
    },
    role(role) {
      this.seletcedRole = role;
    },
  },

  components: {
    Form,
    Field,
    Spinner,
  },

  methods: {
    ...mapActions("user", ["getUser", "userUpdate", "getGroups", "getRole"]),
    ...mapActions("common", ["setSucccessMessageStatus"]),
    ...mapActions("file", ["getPicture"]),

    onSubmit() {
      if (!this.isChangedRank) {
        if (this.editUser.role != "" && this.editUser.role != null) {
          switch (this.editUser.role) {
            case "代表取締役社長":
              this.editUser.rank = "900";
              break;
            case "取締役副社長":
              this.editUser.rank = "800";
              break;
            case "専務取締役":
              this.editUser.rank = "7900";
              break;
            case "取締役":
              this.editUser.rank = "600";
              break;
            case "部長執行役員":
              this.editUser.rank = "500";
              break;
            case "部長":
              this.editUser.rank = "400";
              break;
            case "課長":
              this.editUser.rank = "300";
              break;
            case "係長":
              this.editUser.rank = "200";
              break;
            case "主任":
              this.editUser.rank = "100";
              break;
            default:
              break;
          }
        }
      }

      if (!this.hireDateFormat && !this.departmentValidation) {
        if (this.profilePicture != "") {
          this.updateImage = true;
        } else {
          this.updateImage = false;
        }

        if (this.date != "") {
          this.editUser.published_at = this.date;
        }

        this.updateGroupId = this.defaultGroupId;


        this.userUpdate({
          user: this.editUser,
          userId: this.$route.params.id,
          file: this.userImageUpload,
          fileId: this.profilePicture ? this.profilePicture.id : "",
          updateOrCreate: this.updateImage,
          isBeforeGroupId: this.beforeGroupId,
          updateGroupId: this.updateGroupId,
          isRemovePicture: this.isRemovePicture,
        });
      }
    },
    setRoleRank(e) {
      this.isChangedRank = true;
      this.editUser.rank = e.target.options[e.target.options.selectedIndex].dataset.rank;
      this.departmentValidation = true;

      if (e.target.value == null || e.target.value == "") {
        this.departmentValidation = false;
      } else {
        if (this.updateGroupId != "" && this.updateGroupId != null) {
          this.departmentValidation = false;
        } else {
          this.departmentValidation = true;
        }
      }
    },
    changeDepartment(e) {
      this.updateGroupId = e.target.value;

      if (e.target.value == null || e.target.value == "") {
        if (this.editUser.role != null || this.editUser.role != "") {
          this.departmentValidation = true;
        } else {
          this.departmentValidation = false;
        }
      } else {
        this.departmentValidation = false;
      }
    },

    /**
     * discurd the changes
     * @author Elavarasan
     *  @date 23/10/2021
     */
    discardModal() {
      if (this.gotoList) {
        this.$router.push({ path: "/admin/user" });
      } else {
        this.$router.push({
          name: "User Show",
          params: { id: this.$route.params.id },
        });
      }
    },
    /**
     * close discurd confirm popup
     * @author Elavarasan
     *  @date 23/10/2021
     */
    closeDiscard() {
      $("#discardModal").removeClass("open");
    },
    /**
     * Navigate bredcubms routes
     * @author Elavarasan
     *  @date 23/10/2021
     */
    navigate(url) {
      if (this.formInputEvent == true) {
        $("#discardModal").addClass("open");
      } else {
        this.$router.push({ path: url });
      }
    },
    validateDate(dateString) {
      return moment(dateString, "YYYY-MM-DD", true).isValid();
    },
    navigateToShow() {
      this.gotoList = false;
      if (this.formInputChange == true) {
        $("#discardModal").addClass("open");
      } else {
        this.$router.push({
          name: "User Show",
          params: { id: this.$route.params.id },
        });
      }
    },
    onChange: function (event) {
      this.selectedGroup = [];
      this.group.forEach((element) => {
        if (element.key == event.target.value) {
          this.selectedGroup = element.value;
          if (
            element.key == 1 ||
            element.key == 2 ||
            element.key == 8 ||
            element.key == 9
          ) {
            this.editUser.group = element.value[0];
            this.hideSection = false;
          } else {
            this.hideSection = true;
          }
          return true;
        }
      });
    },
  },
  mounted() {
    let vueThis = this;

    $(document).on("change", ".ec_catch", function (e) {
      let fileObject = new FormData();

      var file = $(this).prop("files")[0];

      fileObject.append("key", "users");
      fileObject.append(
        "display_name",
        $(this)
          .val()
          .replace(/C:\\fakepath\\/i, "")
      );
      fileObject.append("uploadfile", file);
      vueThis.userImageUpload = fileObject;
    });

    $("form").change(function () {
      vueThis.formInputChange = true;
    });

    $("#backToList").click(function () {
      if (vueThis.formInputChange == true) {
        $("#discardModal").addClass("open");
      } else {
        vueThis.$router.push({ path: "/admin/user" });
      }
    });

    $(document).on("click", "button[type=submit]", function () {
      if (
        vueThis.editUser.first_name == "" ||
        vueThis.editUser.last_name == "" ||
        vueThis.editUser.last_name_kana == "" ||
        vueThis.editUser.first_name_kana == "" ||
        vueThis.editUser.email == "" ||
        vueThis.editUser.password == "" ||
        vueThis.editUser.user_code == ""
      ) {
        vueThis.commonValidationError = true;
      } else {
        vueThis.commonValidationError = false;
      }
    });

    $(function () {
      $(document).on("change", ".input-date", function () {
        let date = $(this).datepicker().val().replace(/\//g, "-");

        let fromDate = Date.parse(date);

        if (isNaN(fromDate)) {
          vueThis.hireDateFormat = true;
          return false;
        }

        let dateFormat = vueThis.validateDate(date);

        if (dateFormat) {
          vueThis.editUser.here_date = $(this).datepicker().val().replace(/\//g, "-");
          vueThis.hireDateFormat = false;
        } else {
          vueThis.hireDateFormat = true;
        }
      });

      $(document).on("keyup", ".hour", function () {
        if (isNaN($(this).val()) || parseInt($(this).val()) > 23) {
          vueThis.hoursValidated = false;
          return false;
        } else {
          vueThis.hours = $(this).val();
          vueThis.hoursValidated = true;
          return true;
        }
      });
      $(document).on("keyup", ".minutes", function () {
        vueThis.minutes = $(this).val();

        if (isNaN($(this).val()) || parseInt($(this).val()) > 59) {
          vueThis.minutesValidated = false;
          return false;
        } else {
          vueThis.minutes = $(this).val();

          vueThis.minutesValidated = true;
          return true;
        }
      });
    });

    $(function () {
      $("#ec_delete").on("click", function () {
        $(this)
          .next()
          .after(
            '<div class="filegroup m_b20"><input id="ec_pls" type="file" name="eyecatch" value="" accept=".jpg" class="ec_catch"><label for="ec_pls" class="filelabel">ファイルを選択</label><input type="hidden" name="ec_pastdel" value="del"></div>'
          );
        $("#ec_img").hide();
        $(this).hide();
        vueThis.viewImage = {};
        vueThis.isRemovePicture = true;
      });
    });
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
@import "~@/assets/front_component/js/timepicker/datepicker.css";
</style>
